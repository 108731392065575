import axios from "axios";

export default class Tools {
    static isWeChat() {
        let userAgentInfo = navigator.userAgent.toLowerCase();
        if (userAgentInfo.match(/MicroMessenger/i) === 'micromessenger') {
            document.body.addEventListener('touchmove', function (event) {
                event.preventDefault();
            }, false);
            return true;
        } else {
            return false;
        }
    }

    static isIos() {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        //ios终端
        return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    }

    static downloadByUrl(url, filename) {
        return new Promise(((resolve, reject) => {
            axios.get(url, {responseType: 'arraybuffer'}).then(res => {
                Tools.downloadByBlob(res.data, filename)
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        }));
    }

    static downloadByBlob(blobData, filename) {
        let downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.style.display = 'none';
        let blob = new Blob([blobData]);
        downloadLink.href = URL.createObjectURL(blob);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    static getHashQueryString(url, name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let query = (url || window.location.hash).split("?")[1];
        if (!query) return null;
        let r = query.match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }

    static getQueryString(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
        return null;
    }

    static convertToBase64(file, size = undefined) {
        let allowImageFileSize = size || 2 * 1024 * 1024;
        let reader = new FileReader();
        if (file) {
            return new Promise((resolve, reject) => {
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    if (allowImageFileSize !== 0 && reader.result.length > allowImageFileSize) {
                        let MBSize = Math.floor(allowImageFileSize / 1024 / 1024);
                        reject({allow: MBSize + 'MB', obj: e});
                    } else {
                        resolve(reader.result);
                    }
                }
            })
        }
    }

    /**
     * 删除对象中空属性
     * @param obj 对象
     * @returns {*} 结果
     */
    static deleteEmptyOption(obj) {
        if (!(obj instanceof Object)) {
            return;
        }
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === '' || obj[key] === undefined))
                delete obj[key]
        }
        return obj;
    }
}