import "./index.scss";
import {Button} from "antd";
import {Link} from "react-router-dom";

export default () => {

    const data = [
        {
            "logo": "/images/zhongji.png",
            "name": "中济经络穴位",
            "description": "一款基于3D人体解剖模型的中医专业软件，可在模型上查看经络循行及穴位位置，通过三维模型交互，使用户对中医经络、穴位有更加深刻理解，同时还观看真人取穴视频，进针动画、穴位解剖等专业中医内容。",
            "url": "https://lighttcm.com/",
            "preview": "/images/zhongji-preview.png"
        },
        {
            "logo": "/images/zhiyuan.png",
            "name": "知源中医",
            "description": "一款中医学习APP，汇集名家讲堂视频、中医古籍阅读中药、方剂、经络穴位、疾病百科等中医学习内容，是中医人的随行学习好帮手。",
            "url": "https://www.zhiyuanzhongyi.com/",
            "preview": "/images/zhiyuan-preview.png"
        }
    ];

    const AppInfo = ({logo, name, description, url, preview}) => (
        <div className='app-info' key={name}>
            <div>
                <img src={logo} alt="logo"/>
                <p className='app-name'>{name}</p>
                <p className='description'>{description}</p>
                <Button size='large'><Link to={url}>了解App详情</Link></Button>
            </div>
            <img className='preview' src={preview} alt="preview"/>
        </div>
    )

    return (
        <div className="zhiyuan">
            <img className="slogan" src="/images/slogan.png" alt="slogan"/>
            {data.map(e => AppInfo(e))}
        </div>
    )
}