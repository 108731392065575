import './App.css';
import FeedBack from "./pages/feedback/Feedback";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Frame from "./pages/frame/Frame";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Zhiyuan from "./pages/zhiyuan";

function App() {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Frame />}>
          <Route index element={<Zhiyuan/>} />
          {/*<Route path="medicines" element={<Medicine/>} />*/}
          {/*<Route path="medicine/detail/:name" element={<MedicineDetail />} />*/}
        </Route>
        {/*<Route path="/invite" exact element={<Invite />} />*/}
        {/*<Route path="/auto-renew-agreement" exact element={<AutoRenewAgreement />} />*/}
        {/*<Route path="/prime-membership-contract" exact element={<PrimeMembershipContract />} />*/}
        {/*<Route path="/service-agreement" exact element={<ServiceAgreement />} />*/}
        {/*<Route path="/privacy" exact element={<PrivacyPolicy />} />*/}
        {/*<Route path="/feedback" exact element={<FeedBack />} />*/}
      </Routes>
    </BrowserRouter>
  </>;
}

export default App;
